<template>
  <div class="slider">
    <Carousel wrap-around :settings="carouselSettings">
      <Slide v-for="image in images" :key="image">
        <div
          :style="`background: center / contain no-repeat url(${image})`"
          class="slider__slide"
        />
      </Slide>
      <template #addons="{ slidesCount }">
        <Navigation v-if="slidesCount > 2" />
        <Pagination v-if="slidesCount > 1" />
      </template>
    </Carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

export default {
  name: "ImageCarousel",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  data: () => ({
    carouselSettings: {
      itemsToShow: 1,
    },
  }),
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.slider {
  margin-top: 20px;
  margin-bottom: 20px;

  &__slide {
    width: 100%;
    height: 190px;
  }

  @media (min-width: $media-xs) {
    &__slide {
      height: 440px;
    }
  }

  @media (min-width: $media-sm) {
    margin-top: 60px;

    &__slide {
      height: 440px;
    }
  }

  @media (min-width: $media-lg) {
    &__image {
      margin-top: 87px;
    }
  }

  .carousel__prev {
    left: 5%;
  }

  .carousel__next {
    right: 5%;
  }

  .carousel__prev,
  .carousel__next {
    border-radius: 0;
    background-color: transparent;

    svg {
      color: #000000;
    }

    &:hover {
      background-color: rgba(187, 187, 187, 0.1);
    }
  }

  .carousel__pagination-button {
    background-color: $white;
    &--active {
      background-color: $red;
    }
  }
}
</style>
