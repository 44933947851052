<template>
  <div>
    <Preloader :loading="isVillageLoading" />
    <div v-if="village && !isVillageLoading" class="village">
      <FullImageHero
        :title="village.name"
        :tagline="village.summary"
        :image="village.img"
      >
        <!-- <template #content>
          <Duration :estimated-time="village.estimated_time"></Duration>
        </template> -->
        <template #button>
          <router-link style="text-decoration: none" to="#village-description">
            <Button class="mt-5"> explore </Button>
          </router-link>
        </template>
      </FullImageHero>

      <SectionWithBg id="village-description">
        <template #heading> What is {{ village.name }}? </template>
        <template #body>
          <div class="mr-5">
            {{ village.description }}
          </div>
        </template>
      </SectionWithBg>

      <div v-if="village.exercises.length" class="padding-x exercises">
        <Heading noDecoration text-align="center" class="faq__header">
          Exercise examples:
        </Heading>
        <div
          class="exercises__item"
          v-for="exercise in village.exercises"
          :key="exercise.id"
        >
          <div
            class="exercises__item-img"
            :style="`background-image: url(${
              exercise.image
                ? exercise.image
                : require('../../assets/img/exercise-sample.png')
            } )`"
          ></div>
          <div>
            <div class="exercises__item-title">{{ exercise.name }}</div>
            {{ exercise.description }}
            <div class="exercises__item-time">
              {{ makeEstimatedTime(exercise.estimated_time) }}
            </div>
          </div>
        </div>
        <!--    <div class="exercises__item">
          <div class="exercises__item-img"></div>
          <div>
            <div class="exercises__item-title">Shail Patel</div>
            Checkout the crypto tumbling site to see how cryptocurrencies are
            sent anonymously.
            <div class="exercises__item-time">~5min 30sec</div>
          </div>
        </div>-->
      </div>
      <div v-if="village.images.length" class="padding-x">
        <Heading noDecoration text-align="center" class="faq__header">
          During previous event:
        </Heading>
        <ImageCarousel :images="getImagesArr(village.images)" />
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import Button from "@/components/common/Button";
import FullImageHero from "@/components/common/FullImageHero";
import SectionWithBg from "@/components/common/SectionWithBg";
import ImageCarousel from "../common/ImageCarousel";
import { mapActions } from "vuex";
// import Duration from "@/components/common/Duration";

export default {
  name: "Village",
  props: {},
  components: {
    Preloader,
    Heading,
    Button,
    FullImageHero,
    // Duration,
    SectionWithBg,
    ImageCarousel,
  },
  data: () => ({
    isVillageLoading: false,
    village: null,
  }),
  mounted() {
    this.isVillageLoading = true;
    this.getVillage();
  },
  methods: {
    ...mapActions(["GET"]),
    getVillage() {
      return this.GET({
        route: `public/event/${this.$route.params.id}/village/${this.$route.params.village}`,
      })
        .then((resp) => {
          this.village = resp.data;
          this.isVillageLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isVillageLoading = false;
        });
    },
    makeEstimatedTime(time) {
      if (time <= 60) return `~${time}sec`;
      return `~${~~((time % 3600) / 60)}min ${~~time % 60}sec`;
    },
    createBoxData(time) {
      if (time <= 0) return;
      return {
        name: "Time",
        title: this.makeEstimatedTime(time),
        subtitle: "Time usually spend in village",
      };
    },
    getImagesArr(arr) {
      return arr.map((element) => {
        return element.image_url;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.exercises {
  margin: 50px 0 100px;

  &__item {
    letter-spacing: 0.05em;
    margin-top: 30px;
    &-img {
      width: 100%;
      height: 288px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &-title {
      font-weight: $sansBold;
    }
    &-time {
      font-family: $sarabun;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  @media (min-width: $media-xs) {
    margin: 100px 0;
    &__item {
      &-img {
        width: 332px;
        height: 240px;
        position: relative;
        &::after {
          content: "";
          width: 150px;
          height: 2px;
          background: $red;
          position: absolute;
          top: 10%;
          right: -30%;
        }
      }
    }
  }
  @media (min-width: $media-sm) {
    &__item {
      display: flex;
      margin-top: 50px;
      &-title {
        margin-top: 1%;
      }
      &-img {
        margin-right: 10%;
        min-width: 150px;
        min-height: 200px;
        &::after {
          width: 50%;
        }
      }
    }
  }
  @media (min-width: $media-md) {
    &__item {
      &-title {
        margin-top: 4%;
      }
      &-img {
        min-width: 215px;
        min-height: 290px;
      }
    }
  }
  @media (min-width: $media-xl) {
    &__item {
      &-img {
        margin-right: 20%;
        width: 272px;
        height: 367px;
        &::after {
          width: 168px;
          right: -55%;
        }
      }
    }
  }
}
</style>
